import React, { useEffect, useRef, useState } from 'react'
import 'react-pdf/dist/esm/Page/TextLayer.css'
import 'react-pdf/dist/esm/Page/AnnotationLayer.css'
import { Document, Page,pdfjs } from 'react-pdf'
import Utils from '../../../lib/Utils'
import StateUpdators from '../../../lib/StateUpdators'
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import InstructionsTitle from '../viewable_bits/InstructionsTitle'
import AIPromptInput from '../AIPromptInput'

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'https://computers4kids.co.za/cdn/pdf.worker.mjs',
    import.meta.url,
).toString()

//console.log('pdfjs.GlobalWorkerOptions.workerSrc=',pdfjs.GlobalWorkerOptions.workerSrc)

/**
 * View lesson instructions
 * @param {object} props The props object
 * @returns 
 */
const DSCInstructions = ({lesson,currentPageNumber}) => {
    console.log('{DSCInstructions}')
    const [numPages, setNumPages] = useState(null)
    const [pageNumber, setPageNumber] = useState(currentPageNumber?currentPageNumber:1)
    const [lan, setLanguage] = useState('eng')
    StateUpdators.setLanguage = setLanguage
    // eslint-disable-next-line
    let newPage = 0;
    StateUpdators.setPageNumber = setPageNumber
    /**
     * Set the number of pages
     * @param {object} param0 The arg object
     */
    const onDocumentLoadSuccess = ({numPages}) => {
        setNumPages(numPages)
    }
    const booklet = `${Utils.assetsURL}${lesson.lesson.booklet[lan]}`
    const _lesson = useRef({lessonId: lesson.lesson.lessonId, courseId: lesson.lesson.courseId})
    useEffect(() => {
        Utils.attachAIBotActions({lessonId: _lesson.current.lessonId})
        Utils.addAnalytics({
            page: 'Instructions',
            url: window.location.href,
            title: 'NS.Instructions',
            lessonId: _lesson.current.lessonId,
            courseId: _lesson.current.courseId
        })
    },[])
    //console.log('lesson.lesson.testMode=',lesson.lesson.testMode,'lesson.lesson.integratedActivity=',lesson.lesson.integratedActivity)
    return (
        <>
        {
            lesson.lesson.testMode?.state && (lesson.lesson?.id??false) ?
            <>
            <InstructionsTitle _lesson={_lesson.current} {...lesson} />
            <div className='bgg-booklet nndjdjdj w-90 h-centred rounded-small animate__animated animate__fadeIn'>
                <div className='nhs-oeoejs rounded shadow box-b w-60 centred' dangerouslySetInnerHTML={{__html: lesson.lesson.integratedActivity.info}}>     
                </div>
            </div>
            </> :
            <>
            <InstructionsTitle _lesson={_lesson.current} {...lesson} />
            <div className='bgg-booklet nndjdjdj w-90 h-centred rounded-small animate__animated animate__fadeIn'>
                <div className='pages-handle'>
                    <div title='Navigate booklet pages' className='bcgdg-nums ptr bkg-white h-centred rounded shadow'>
                        <div className='hfgfg-flex'>
                            <ArrowBackIosIcon className='ptr' titleAccess='Previous page' onClick={() => {
                                Utils.addAnalytics({
                                    page: 'Lesson.Instructions',
                                    url: window.location.href,
                                    title: `NS.Lesson.Instructions.PageNumber.N_${pageNumber}`,
                                    action: 'BackButton',
                                    lessonId: _lesson.current.lessonId,
                                    courseId: _lesson.current.courseId
                                });
                                if(pageNumber > 1) {
                                    setPageNumber(newPage = pageNumber-1);
                                    Utils.instructionsCurrentPage = pageNumber-1;
                                }
                            }} />
                        </div>
                        <div className='hfgfg-flex'>
                            <p>Page {pageNumber} of {numPages}</p>
                        </div>
                        <div className='hfgfg-flex'>
                            <ArrowForwardIosIcon className='ptr' titleAccess='Next page' onClick={() => {
                                Utils.addAnalytics({
                                    page: 'Lesson.Instructions',
                                    url: window.location.href,
                                    title: `NS.Lesson.Instructions.PageNumber.N_${pageNumber}`,
                                    action: 'ForwardButton',
                                    lessonId: _lesson.current.lessonId,
                                    courseId: _lesson.current.courseId
                                });
                                if(pageNumber < numPages) {
                                    setPageNumber(newPage = pageNumber+1)
                                    Utils.instructionsCurrentPage = pageNumber+1;
                                }
                            }} />
                        </div>
                    </div>
                </div>
                <Document file={booklet} onLoadSuccess={onDocumentLoadSuccess} >
                    <Page className={'instruction_booklet shadow-cool'} pageNumber={Utils.instructionsCurrentPage>0?Utils.instructionsCurrentPage:pageNumber} />
                </Document>
            </div>
            </>
        }
        <AIPromptInput />
        </>
    )
}

export default DSCInstructions